import React from 'react'

const DetailsSubcategory = () => {
  return (
    <div>
      <h1>Details Subcategory</h1>
    </div>
  )
}

export default DetailsSubcategory
