import React from 'react'

const OrderTracking = () => {
  return (
    <div>
      <h1> Orders Tracking</h1>
    </div>
  )
}

export default OrderTracking
