import React from 'react'

const AdsPopupList = () => {
  return (
    <div>
      <h1>Ads Popup List</h1>
    </div>
  )
}

export default AdsPopupList
